/**
 * NOTE(minho): **FOCUS** is very important for receiving QR codes (almost all UX parts).
 * 
 * Mainly, we want to hold the focus.
 * But during the handling the received QR code, we lose the focus intentionally.
 * Except that, we should maintain the focus manually.
 */

import React, { useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
// import { useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from "@sentry/react";
import { SpanStatus } from "@sentry/tracing";

import PopUpWrapper, { FAKE_ADMIN } from "./popups/PopUpWrapper";
import QrKeyboardReader from "./QrKeyboardReader";
import LandingPagePopup from "./popups/LandingPagePopup";
import { defaults, userAddress, nftId, adminAddressAsFlag } from "../recoil/index";

// HMP1,2
import { HMP_1_2_OwnerCheck } from "../utils/opensea";
// HMP3
import { hmp3_contract_read_only } from "../contract";
import { checkIsNftHolder } from "../utils/contractUtils";
import { enteringUser, GetAdmins } from "../utils/firebase";


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
    font-family: 'Inter';
    font-weight: 400;
    background-color: black;
  }

  @keyframes disappear {
    0%{
      opacity: 1;
    }
    50%{
      opacity: 0.75;
    }
    80%{
      opacity: 0.5;
    }
    99% {
      opacity: 0;
    }
    100%{
      display: none;
      opacity: 0;
    }
  }  
`;

export default function SmartGate() {
  const qrKeyboardRef = useRef();
  const [isAdminHere, setAdminAddress] = useRecoilState(adminAddressAsFlag);
  const [address, setAddress] = useRecoilState(userAddress);
  const setNftId = useSetRecoilState(nftId);
  const [isRevisited, setIsRevisited] = useState(false);
  const [isClickEnterBtn, setIsClickEnterBtn] = useState(false);
  const { data: ADMINS, error, isLoading } = GetAdmins();

  // const { store_type, store_location } = useParams();

  const handleClickBackground = () => {
    qrKeyboardRef.current.forceFocusForReceivingQRCode();
  };

  const _hmp3Contract = hmp3_contract_read_only();

  const handleOnEthAddress = async (_address) => {

    if (ADMINS.includes(_address)) {
      if (!isAdminHere) {
        setAdminAddress(_address);
      }
      return;
    }

    // FIXME: test
    if (!_address.startsWith('0x')) {
      const __TEST_CASE_HERE__ = _address;
      switch (__TEST_CASE_HERE__) {
        case '1':
          setIsRevisited(true);
          setNftId(1);
          break;
        case '2':
          setIsRevisited(true);
          setNftId(2);
          break;
        case '3':
          setIsRevisited(true);
          setNftId(3);
          break;
        default:
        case 'no':
          setIsRevisited(false);
          break;
        case 'mint':
          setIsRevisited(false);
          setAdminAddress(FAKE_ADMIN);
          break;
      }
      setIsClickEnterBtn(false);
      setAddress(_address);
      return;
    }

    setIsClickEnterBtn(true);
    setIsRevisited(false);
    setNftId(defaults.nftId);

    if (isAdminHere) {
      // NOTE: (IN MINT PHASE) DO NOT CHECK THE OWNERS, JUST MINT IT.
      setIsRevisited(false);

      // NOTE: goto next page
      setIsClickEnterBtn(false);
      setAddress(_address);
      return;
    }

    // TODO: pull this out
    const _runPromiseOnTranscation = (_promise, _transactionName) => {
      const transaction = Sentry.startTransaction({ name: _transactionName });
      // FIXME: location - transaction.setTag();
      return _promise
        .then((ret) => {
          transaction.setStatus(SpanStatus.Ok);
          return ret;
        })
        .catch((e) => {
          Sentry.captureException(e);
          transaction.setStatus(SpanStatus.InternalError);
        })
        .then((ret) => {
          transaction.finish();
          return ret;
        });
    };
    
    const [hmp1_2_owner, is_hmp3_owner, already_visited_today] = await Promise.all([
      _runPromiseOnTranscation(HMP_1_2_OwnerCheck(_address), 'Opensea: HMP1,2').catch(() => [false, false]),
      _runPromiseOnTranscation(checkIsNftHolder({ contract: _hmp3Contract, userAddress: _address }), 'Infura: HMP3'),
      _runPromiseOnTranscation(enteringUser(_address), 'Backend: visit api'),
      // FIXME:  -> ret asap.
    ]);

    const [is_hmp1_owner, is_hmp2_owner] = hmp1_2_owner;
    if (is_hmp1_owner || is_hmp2_owner || is_hmp3_owner) {
      toast.info(`NFT소유 확인: ${ is_hmp1_owner ? '1차' : '' } ${ is_hmp2_owner ? '2차' : '' } ${ is_hmp3_owner ? '3차': '' }`, { theme: 'dark' });
    }
    if (is_hmp1_owner) {
      setIsRevisited(true);
      setNftId(1);
    } else if (is_hmp2_owner) {
      setIsRevisited(true);
      setNftId(2);
    } else if (is_hmp3_owner) {
      setIsRevisited(true);
      setNftId(3);
    } else {
      setIsRevisited(false);
    }

    // NOTE: goto next page
    setIsClickEnterBtn(false);
    setAddress(_address);
  };
  
  return (
    <div>
      <GlobalStyle />
      {address === defaults.userAddress ? (
        <LandingPagePopup
          qrKeyboardRef={qrKeyboardRef}
          handleClickBackground={handleClickBackground}
          isClickEnterBtn={isClickEnterBtn}
        />
      ) : (
        <PopUpWrapper isRevisited={isRevisited} />
      )}
      <QrKeyboardReader ref={qrKeyboardRef} onEthAddress={handleOnEthAddress} />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
      />
    </div>
  );
}
