import env from "react-dotenv";
import useSWR from 'swr';

export const enteringUser = (address) => {
  if (!env.VISIT_API_PATH) return false;
  const data = {
    address,
    sharedSecret: env.SHARED_SECRET,
    // TODO: store_type, store_location
    // FIXME: encryption
  };
  console.debug(`Entering User: ${address}`);
  return fetch(env.VISIT_API_PATH, {
    method: "POST",
    body: JSON.stringify({ data }),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((ret) => {
      if (!ret.ok) {
        throw new Error(`${ret.status}: ${ret.statusText}.`);
      }
      return ret.json().then(_ => _.result);
    });
};

export const GetAdmins = () => {
  const DEFAULT_ADMINS = [
    '0xCEbF611D6e47C411D345A6a07DD2Babf34CAdD0F', // hide me please admin
  ];
  if (!env.GET_ADMIN_API_PATH) return { data: DEFAULT_ADMINS };
  const data = {
    sharedSecret: env.SHARED_SECRET,
  };
  // TODO: _runPromiseOnTranscation
  const fetcher = (...args) => fetch(...args, {
    method: "POST",
    body: JSON.stringify({ data }),
    headers: {
      "content-type": "application/json",
    },
  })
    .then(res => {
      if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}.`);
      }
      return res.json().then(_ => _.result);
    });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSWR(
    env.GET_ADMIN_API_PATH,
    fetcher,
    {
      // NOTE: reduce refreshInterval of admins api
      // TODO: webpush for force refresh
      refreshInterval: 1000 * 60 * 60 * 2,
      fallbackData: DEFAULT_ADMINS,
    },
  );
}
