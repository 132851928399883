export const ethereumAddressParser = (str) => {
  const found = str.match(/(0x[A-Fa-f0-9]{40})/g);
  if (found && found.length) {
    return found[0];
  }
};

export const formatAddress = (address) => {
  return address
    ? `${address.substring(0, 7)}...${address.substring(address.length - 5)}`
    : '';
};

export const getRandomNftId = (max) => {
  return Math.floor(Math.random() * max) + 1;
};
