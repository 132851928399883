import env from "react-dotenv";
import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import * as Sentry from "@sentry/react";

import GachaPopup from "./GachaPopup";
import WelcomeAgainPopup from "./WelcomeAgainPopup";
import BenefitPopup from "./BenefitPopup";
import ShouldBuyNFTPopup from "./ShouldBuyNFTPopup";
import { userAddress, adminAddressAsFlag, nftId } from "../../recoil";


export const FAKE_ADMIN = "FAKE ADMIN";


export default function PopUpWrapper({ isRevisited }) {
  const [shownGotcha, setShownGotcha] = useState(false);
  const [showBenefit, setShowBenefit] = useState(false);
  const [isMintingStarted, setMintingStart] = useState(false);
  const [address, ] = useRecoilState(userAddress);
  const [adminAddress, setAdminAddress] = useRecoilState(adminAddressAsFlag);
  const setNftId = useSetRecoilState(nftId);

  const handleMinting = async (adminAddress, address) => {
    if (adminAddress === FAKE_ADMIN) {
      return;
    }

    if (env.MINT_API_PATH && env.SHARED_SECRET) {
      // TODO: pull this out to firebase.js
      const transaction = Sentry.startTransaction({ name: 'Backend: Mint' });
      const data = {
        toAddress: address,
        adminAddress: adminAddress,
        // TODO: store_type, store_location
      };
      console.debug('Minting', data );
      data.sharedSecret = env.SHARED_SECRET;  // FIXME: encryption!!!!

      transaction.setData('toAddress', address);
      transaction.setData('adminAddress', adminAddress);
      // TODO: location - transaction.setTag();

      await fetch(env.MINT_API_PATH, {
        method: "POST",
        body: JSON.stringify({ data }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((ret) => {
          transaction.setHttpStatus(ret.status);
          // FIXME: error handling on server.
        })
        .catch((err) => {
          Sentry.captureException(err);
        })
        .then(() => {
          transaction.finish();
        });
    }
  };

  useEffect(() => {
    // NOTE: isMintingStarted will be reset on initialize.
    if (isMintingStarted) {
      (async () => {
        await handleMinting(adminAddress, address)
          .then(() => {
            setAdminAddress(null);
          });
        // NOTE(minho): POST event fired here,
        //              and run it on out of this component's lifecycle.
      })();

      setNftId(3);
      setShowBenefit(true);
    }
  }, [isMintingStarted]);

  useEffect(() => {
    // NOTE: adminAddress will be given on initialize.
    // NOTE: adminAddress timeout can be faster then <GachaPopup>'s timeout.
    if (adminAddress) {
      setShownGotcha(true);
    }
  }, [adminAddress]);

  if (showBenefit) {
    // isRevisited -> showBenefit
    // !isRevisited -> adminAddress & shownGotcha -> handleMinting & showBenefit
    return <BenefitPopup />;
  }
  if (isRevisited) {
    return <WelcomeAgainPopup setShowBenefit={setShowBenefit} />;
  }
  if (/* adminAddress */ shownGotcha) {
    return <GachaPopup setMintingStart={setMintingStart} />;
  }

  // you are not allowed in this party.
  return <ShouldBuyNFTPopup />;
}
