import env from "react-dotenv";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const openseaFetch = async (collectionKey, _retryCnt=0) => {
  // 1st Calm mind: 13160607876627315997298998607605372076388631947155285122643479581240930074649
  // 2nd Relaxed mind: 13160607876627315997298998607605372076388631947155285122643479596634092863513
  return fetch(`https://api.opensea.io/api/v1/asset/0x495f947276749ce646f68ac8c248420045cb7b5e/${collectionKey}/owners?limit=50`)
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((responseJson) => {
            // TODO: .owner.user.username (nullable)
            // TODO: .owner.profile_img_url
            return responseJson.owners.map(_ => _.owner.address.toLowerCase());
          });
      } else if ([429, 500, 501, 502, 503].includes(response.status)) {
        // TODO: retry!!!! with sleep! now very naive.
        return sleep(1000).then(() => openseaFetch(collectionKey, _retryCnt + 1));
      } else if (response.status === 404) {
        console.log("WRONG OPENSEA TOKEN KEY");
      }
      return [];
    });
};

export const HMP_1_2_OwnerCheck = async (_address) => {
  // TODO: caching...

  if (typeof env.HMP1_ERC1155_OPENSEA_TOKEN_ID === 'undefined' || typeof env.HMP2_ERC1155_OPENSEA_TOKEN_ID === 'undefined') {
    console.log("NO OPENSEA TOKEN KEY");
    return [false, false];
  }
  
  const __address = _address.toLowerCase();
  return Promise.all([
    openseaFetch(env.HMP1_ERC1155_OPENSEA_TOKEN_ID),
    openseaFetch(env.HMP2_ERC1155_OPENSEA_TOKEN_ID),
  ]).then(([hmp1Owners, hmp2Owners]) => {
    return [hmp1Owners.includes(__address),hmp2Owners.includes(__address)];
  });
}