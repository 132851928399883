import React, { useState } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: transparent;
    touch-action: none;
  }
`;

const TransformContainer = styled.div`
  overflow: hidden;
`;

const BenefitPageButton = styled.button`
  position: absolute;
  right: 5vw;
  top: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 4vw;
  padding-right: 4vw;
  font-size: 3vh;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 2vw;
`;

export default function WholeBenefitPopupButtonAndPopup() {
  const [toggled, setToggled] = useState(false);
  const props = {
    zoomAnimation: {
      disabled: true,
    },
  };

  return (
    <StyledPopup
      onOpen={() => setToggled(true)}
      trigger={
        <BenefitPageButton
          style={{
            backgroundColor: toggled
              ? "rgba(255, 119, 127, 0.71)"
              : "rgba(119, 214, 255, 0.71)",
          }}
        >
          {toggled ? "NFT 혜택 닫기" : "NFT 혜택 보기"}
        </BenefitPageButton>
      }
      modal
      nested
      onClose={() => setToggled(false)}
    >
      {() => (
        <TransformContainer>
          <TransformWrapper
            centerOnInit={true}
            initialScale={1}
            minScale={1}
            maxScale={2}
            zoomAnimation={props.zoomAnimation}
          >
            {() => (
              <TransformComponent>
                <img
                  src="/image/benefit-page.webp"
                  alt="benefit-page"
                  style={{ width: "70vw" }}
                />
              </TransformComponent>
            )}
          </TransformWrapper>
        </TransformContainer>
      )}
    </StyledPopup>
  );
}
