import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import env from "react-dotenv";

import App from "./App";

if (env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    release: env.SENTRY_RELEASE,
    integrations: [new BrowserTracing({
      // tracePropagationTargets: [
      //   // "api.opensea.io",
      //   // "mainnet.infura.io",
      //   // FIXME: env.MINT_API_PATH
      //   "localhost",
      //   /^\//,
      // ],
    })],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    attachStacktrace: true,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <Routes>
        { /* FIXME: store_type, store_location */ }
        <Route path='/admin' element={ <App /> } />
        <Route path='/:store_type/:store_location' element={ <App /> } />
        <Route path='*' element={ <App /> } />
      </Routes>
    </BrowserRouter>
  </RecoilRoot>
);
