import React from "react";

import SmartGate from "./components/SmartGate";

function App() {
  return (
    <div className="App" style={{ touchAction: "none" }}>
      <SmartGate />
    </div>
  );
}

export default App;
