import styled from "styled-components";
import { useRecoilState } from "recoil";
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';

import AddressBox from "../ui/AddressBox";
import { userAddress, adminAddressAsFlag, defaults } from "../../recoil";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/image/main.webp");
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NftCard = styled.img`
  width: 50vw;
  // TODO: height;
  border-radius: 5vw;
  box-shadow: inset 2px 4px 4px rgba(255, 255, 255, 0.25);
  margin: 13vh auto 3vh auto;
`;

const LeftTime = styled.div`
  font-weight: 700;
  font-size: 3vw;
  text-align: center;
  margin: 1vh auto 0vh auto;
  color: #ffffff;
  opacity: 0.5;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 5vw;
  position: absolute;
  bottom: 7vh;
`;

const Button = styled.button`
  width: 35vw;
  height: 7vh;
  margin: 2vh auto 0vh auto;
  border-radius: 10vw;
  font-weight: 700;
  font-size: 3.5vw;
  background: ${(props) => props.background};
  border: 0;
  justify-content: center;
  align-items: center;
`;


export default function GachaPopup({ setMintingStart }) {
  const [time, setTime] = useState(7);
  const [address, setAddress] = useRecoilState(userAddress);
  const [, setAdminAddress] = useRecoilState(adminAddressAsFlag);

  const outOfTime = () => {
    setAddress(defaults.userAddress);
    setAdminAddress(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (time > 1) {
        setTime((prev) => prev - 1);
      } else {
        outOfTime();
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  return (
    <Container>
      <NftCard src="/image/nft/stamp3.webp" />
      <AddressBox>{address}</AddressBox>
      <LeftTime>{time} sec ...</LeftTime>
      <div style={{ display: "flex", marginTop: "10px", gap: "20px" }}>
        <Button background="#ABFC6C" onClick={() => {
          setMintingStart(true);
          toast.info(`민팅이 진행중입니다.`, { theme: 'dark' });
        }}>구매 확인</Button>
        <Button background="#FF5555" onClick={() => {
          outOfTime();
          toast.warn(`민팅을 취소합니다.`, { theme: 'dark' });
        }}>구매 취소</Button>
      </div>
      <IconContainer>
        <img src="/icon/hidemeplease.svg" alt="hide me please icon" />
        <img src="/icon/ainetwork.svg" alt="ai network icon" />
      </IconContainer>
    </Container>
  );
}
