export const checkIsNftHolder = async ({ contract, userAddress }) => {
  return (await contract.balanceOf(userAddress)).toNumber() !== 0;
};

export const getBalanceOf = async ({ contract, userAddress }) => {
  const balance = await contract.balanceOf(userAddress);
  return balance;
};

export const getTokensOf = async ({ contract, userAddress }) => {
  const balance = await getBalanceOf({
    contract: contract,
    userAddress: userAddress,
  });
  const tokens = await contract.tokensOf(userAddress, 0, balance);
  return tokens;
};

export const getUserNftUri = async ({ contract, userAddress }) => {
  const tokens = await getTokensOf({
    contract: contract,
    userAddress: userAddress,
  });
  const tokenURI = await contract.tokenURI(tokens[0]);
  return tokenURI;
};
