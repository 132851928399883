import styled from "styled-components";
import { useRecoilState } from "recoil";
import React, { useState, useEffect } from "react";

import AddressBox from "../ui/AddressBox";
import { userAddress, defaults } from "../../recoil";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/image/main.webp");
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NftCard = styled.img`
  width: 50vw;
  border-radius: 5vw;
  margin: 13vh auto 3vh auto;
  box-shadow: inset 2px 4px 4px rgba(255, 255, 255, 0.25);
  filter: blur(3px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const LeftTime = styled.div`
  font-weight: 700;
  font-size: 3vw;
  text-align: center;
  margin: 1vh auto 0vh auto;
  color: #ffffff;
  opacity: 0.5;
`;

const Welcomeback = styled.div`
  font-family: "Inter";
  font-weight: 700;
  font-size: 4vw;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 3vh auto 0vh auto;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 5vw;
  position: absolute;
  bottom: 7vh;
`;

export default function ShouldBuyNFTPopup() {
  const [address, setAddress] = useRecoilState(userAddress);
  const [time, setTime] = useState(5);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (time > 1) {
        setTime((prev) => prev - 1);
      } else {
        setAddress(defaults.userAddress);
      }
      return () => {
        clearTimeout(timer);
      };
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Container>
      <NftCard src={`/image/nft/stamp3.webp`} />
      <AddressBox>{address}</AddressBox>
      <LeftTime>{time} sec ...</LeftTime>
      <Welcomeback>소유가 확인되지 않습니다.<br/>NFT 멤버쉽을 구매하고 다양한 혜택을 받아보세요.</Welcomeback>
      <IconContainer>
        <img src="/icon/hidemeplease.svg" alt="hide me please icon" />
        <img src="/icon/ainetwork.svg" alt="ai network icon" />
      </IconContainer>
    </Container>
  );
}
