import styled from "styled-components";

import formatAddress from "../../utils/formatAddress";

const Container = styled.div`
  padding: 0vw 2vh;
  display: flex;
  background: rgba(231, 238, 241, 0.3);
  border-radius: 4vw;
  width: fit-content;
  margin: 0 auto;
`;

const Content = styled.span`
  font-weight: 400;
  font-size: 3vw;
  line-height: 48px;
  text-align: center;
  color: #77d6ff;
`;

export default function AddressBox({ children }) {
  return (
    <Container>
      <Content>{formatAddress(children)}</Content>
    </Container>
  );
}
