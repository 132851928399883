import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import React from "react";

import { userAddress, nftId, defaults } from "../../recoil";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/image/stamp${(props) => props.nftId}-benefit.webp");
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 50vw;
  height: 9vh;
  background: ${(props) => props.background};
  border-radius: 10vw;
  display: flex;
  position: absolute;
  bottom: ${(props) => (props.nftId === 3 ? "5vh" : "11vh")};
  font-weight: 700;
  font-size: 5vw;
  color: #000000;
  border: 0;
  justify-content: center;
  align-items: center;
`;

export default function BenefitPopup() {
  const _nftId = useRecoilValue(nftId);
  const setAddress = useSetRecoilState(userAddress);

  const getButtonBackground = (nftId) => {
    switch (nftId) {
      case 1:
        return "#FCE56C";
      case 2:
        return "#ABFC6C";
      case 3:
        return "#FF5555";
      default:
        return "#FCE56C";
    }
  };

  return (
    <Container nftId={_nftId}>
      <Button
        nftId={_nftId}
        background={getButtonBackground(_nftId)}
        onClick={() => {
          setAddress(defaults.userAddress);
        }}
      >
        확 인
      </Button>
    </Container>
  );
}
