import { ethers, Contract } from 'ethers';
import env from "react-dotenv";
import abi from './hmp3_abi.json';

export const hmp3_contract_read_only = () => {
  const networkName = env.NETWORK|| 'goerli';
  const infuraApiKey = env.INFURA_API_KEY;
  const contractAddress = env.HMP3_ERC721_CONTRACT_ADDRESS;
  const contractAbi = abi;

  const provider = new ethers.providers.InfuraProvider(
    networkName,
    infuraApiKey
  );

  return new Contract(contractAddress, contractAbi, provider);
};
