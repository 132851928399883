import React, { useRef, useImperativeHandle, forwardRef, useState } from "react";
import env from "react-dotenv";

import { ethereumAddressParser } from "../utils/utils";

const KEYCODE_ENTER = 13;

const QrKeyboardReader = forwardRef(({ onEthAddress }, ref) => {
  const qrInput = useRef();
  const [isTest, setTest] = useState(false);

  const forceFocusForReceivingQRCode = () => {
    console.debug("QR Reader: focus!");
    qrInput.current?.focus();
  };

  const forceBlurForReceivingQRCode = () => {
    qrInput.current?.blur();
  };

  useImperativeHandle(ref, () => ({
    forceFocusForReceivingQRCode,
    forceBlurForReceivingQRCode,
  }));

  const onKeyDown = async (event) => {
    console.debug(event);
    if (event.keyCode === KEYCODE_ENTER) {
      let typed = event.target.value;
      event.target.value = "";
      if (!isTest && typed === env.TEST_KEY) {
        setTest(true);
        forceFocusForReceivingQRCode();
        return;
      }
      if (isTest && !typed.startsWith('0x')) {
        ;
      } else {
        setTest(false);
        typed = ethereumAddressParser(typed);
      }
      if (onEthAddress && typed) {
        await onEthAddress(typed).catch((err) => {
          forceFocusForReceivingQRCode();
          throw err;
        });
      } else {
        forceFocusForReceivingQRCode();
      }
    }
  };

  const hidden = {
    display: "block",
    border: "none",
    margin: "0px",
    padding: "0px",
    width: 0,
    height: 0,
    opacity: 0,
  };

  return (
    <input
      type="text"
      autoFocus
      style={hidden}
      tabIndex="-1"
      ref={qrInput}
      onKeyDown={onKeyDown}
    />
  );
});

export default QrKeyboardReader;
