import { atom } from "recoil";

export const defaults = Object.freeze({
  nftId: null,
  userAddress: null,
});

export const nftId = atom({
  key: "nftId",
  default: defaults.nftId,
});

export const userAddress = atom({
  key: "userAddress",
  default: defaults.userAddress,
});

export const adminAddressAsFlag = atom({
  key: "adminAddress",
  default: null,
});
