import env from "react-dotenv";
import { useEffect } from "react";
import styled from "styled-components";
import FadeLoader from "react-spinners/FadeLoader";
import { useRecoilState } from "recoil";
import { adminAddressAsFlag } from "../../recoil/index";
import WholeBenefitPopupButtonAndPopup from "./WholeBenefitPopupButtonAndPopup";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/image/landing-page.webp");
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DevTooltip = styled.div`
  position: absolute;
  left: 100px;
  top: 20px;
  padding: 20px;
  font-size: 30px;
  color: rgb(255, 255, 255);
  background-color: rgb(48, 49, 51);
  border-radius: 16px;
`;

const ADMIN_TIMER = 10 * 1000;
const AdminTooltip = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 20px;
  font-size: 30px;
  color: rgb(255, 255, 255);
  background-color: rgb(48, 49, 51);
  border-radius: 16px;

  animation-name: disappear;
  animation-duration: ${ADMIN_TIMER}ms;
  animation-fill-mode: none;
`;

const SpinnerContainer = styled.div`
  margin: 900px auto 0 auto;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 5vw;
  position: absolute;
  bottom: 7vh;
`;

function LandingPagePopup({
  qrKeyboardRef,
  handleClickBackground,
  isClickEnterBtn,
}) {
  const [isAdminHere, setAdminAddress] = useRecoilState(adminAddressAsFlag);

  useEffect(() => {
    qrKeyboardRef.current?.forceFocusForReceivingQRCode();
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      qrKeyboardRef.current?.forceBlurForReceivingQRCode();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let _;
    if (isAdminHere) {
      if (_) clearTimeout(_);
      _ = setTimeout(() => {
        setAdminAddress(null);
        _ = undefined;
      }, ADMIN_TIMER);
    }
    return () => {
      if (_) {
        clearTimeout(_);
        _ = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminHere]);

  return (
    <Container onClick={handleClickBackground}>
      {isAdminHere && <AdminTooltip>Mint</AdminTooltip>}
      {env.NETWORK === "goerli" && <DevTooltip>Development Mode</DevTooltip>}
      <WholeBenefitPopupButtonAndPopup />
      <SpinnerContainer>
        {isClickEnterBtn && (
          <FadeLoader color="#ffffff" height={15} margin={15} />
        )}
      </SpinnerContainer>
      <IconContainer>
        <img src="/icon/hidemeplease.svg" alt="hide me please icon" />
        <img src="/icon/ainetwork.svg" alt="ai network icon" />
      </IconContainer>
    </Container>
  );
}

export default LandingPagePopup;
